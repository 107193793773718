<template>
  <CModal
    id="leavePageModal"
    :show.sync="warningLeavePage"
    :centered="true"
    :closeOnBackdrop="false"
  >
    <div class>
      <div class="tit-leave">
        <h4>{{$t('STAKING_MODAL_WARNING_COMPONENT_LABEL_TITLE')}}</h4>
      </div>
      <p class="text-modal">{{$t('STAKING_MODAL_WARNING_COMPONENT_LABEL_SUB_TITLE')}}</p>
      <p class="text-modal">{{$t('STAKING_MODAL_WARNING_COMPONENT_LABEL_CONFIRM_TEXT')}}</p>
    </div>

    <template #footer>
      <CButton
        class="stay-btn"
        @click="closeModal()"
        color="dark"
        variant="outline"
      >{{$t('STAKING_MODAL_WARNING_COMPONENT_BUTTON_STAY_ON_PAGE')}}</CButton>
      <router-link
        class="leave-btn"
        :to="linkTo"
      >{{$t('STAKING_MODAL_WARNING_COMPONENT_BUTTON_LEAVE_PAGE')}}</router-link>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'StakingModal',
  props: {
    linkTo: {
      type: String,
      default: '',
    },
    warningLeavePage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal', false);
    },
  },
};
</script>

<style lang="scss">
#leavePageModal {
  .modal-dialog {
    max-width: 450px;

    .modal-header {
      display: none;
    }

    .modal-body {
      padding: 24px 20px 0 20px;

      .tit-leave {
        margin-bottom: 20px;

        h4 {
          color: #4a4a4a;
          /* font-family: "SF UI Display"; */
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin: 0;
        }
      }

      .text-modal {
        color: #4a4a4a;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    .modal-footer {
      border: none;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 25px 20px 12px 20px;

      .stay-btn {
        border: 1px solid #cbcbcb;
        border-radius: 5px;
        height: 37px;
        line-height: 37px;
        color: #4a4a4a;
        // font-family: "SF UI Display";
        font-size: 14px;
        line-height: 16px;
        padding: 0 20px;

        &:hover,
        &:visited {
          outline: none;
          background: #fcfcfc;
          color: #4a4a4a;
        }
      }

      .leave-btn {
        display: flex;
        align-items: center;
        height: 37px;
        line-height: 16px;
        padding: 0 20px;
        border-radius: 4px;
        background-color: #3b4b7e;
        color: #ffffff;
        // font-family: "SF UI Display";
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
